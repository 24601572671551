import React from 'react';
import './testimonial.css';
import Slider from 'react-slick';
import {Container, Row, Col} from "reactstrap"


const Testimonials = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true
  };

  return <section>
    <Container>
        <Row>
            <Col lg='12' className='mb-5 text-center'>
                <h2>What my clients says</h2>
            </Col>

            <Col lg='6' className='m-auto'>
                <Slider {...settings}>
                  <div>
                    <div className="single_testimonial">
                      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo omnis consequuntur nihil voluptate porro, nostrum quasi ducimus, labore dolor obcaecati sit similique aperiam quae voluptas! Quisquam excepturi, quaerat id autem, repudiandae libero quo est nisi obcaecati exercitationem quod cupiditate molestias.</p>

                      <h6>Martin Cooper</h6>
                      <p className='title'>Web Developer</p>
                    </div>
                  </div>

                  <div>
                    <div className="single_testimonial">
                      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo omnis consequuntur nihil voluptate porro, nostrum quasi ducimus, labore dolor obcaecati sit similique aperiam quae voluptas! Quisquam excepturi, quaerat id autem, repudiandae libero quo est nisi obcaecati exercitationem quod cupiditate molestias.</p>

                      <h6>Martin Cooper</h6>
                      <p className='title'>Web Developer</p>
                    </div>
                  </div>

                  <div>
                    <div className="single_testimonial">
                      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo omnis consequuntur nihil voluptate porro, nostrum quasi ducimus, labore dolor obcaecati sit similique aperiam quae voluptas! Quisquam excepturi, quaerat id autem, repudiandae libero quo est nisi obcaecati exercitationem quod cupiditate molestias.</p>

                      <h6>Martin Cooper</h6>
                      <p className='title'>Web Developer</p>
                    </div>
                  </div>
                </Slider>
            </Col>
        </Row>
    </Container>
  </section>
}

export default Testimonials
