import React, { Fragment } from 'react'
import Experience from '../experience/Experience';
import Header from '../header/Header';
import HeroSection from '../hero-section/HeroSection';
import Services from '../services/Services';
import Portfolio from '../portfolio/Portfolio';
import About from './../about/About';
import Testimonials from '../testimonial/Testimonials';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';

const Layout = () => {
  return <Fragment>
    <Header/>
    <HeroSection/>
    <About/>
    <Services/>
    <Experience/>
    <Portfolio/>
    <Testimonials/>
    <Contact/>
    <Footer/>
  </Fragment>
}

export default Layout