import React, {useState} from "react";
import "./about.css";

import { Container, Row, Col } from "reactstrap";

import aboutImg from "../../assets/hero.jpg";

import Education from "../education/Education";
import Skills from "../skills/Skills";
import Award from "../award/Award";

const About = () => {

  const [aboutFilter, setAboutFilter] = useState('ABOUT');
  return (
    <section id="about">
      <Container>
        <Row>
          <Col lg="12" className="mb-5">
            <h2>About Me</h2>
          </Col>

          <Col lg="4" md="3">
            <div className="about_btns d-flex flex-column align-items-center">
              <button className={`about_btn ${aboutFilter === 'ABOUT' ? 'about_btn-active' : ''}`} onClick={()=> setAboutFilter('ABOUT')}>About me</button>
              <button className={`about_btn ${aboutFilter === 'EDUCATION' ? 'about_btn-active' : ''}`} onClick={()=> setAboutFilter('EDUCATION')}>Education</button>
              <button className={`about_btn ${aboutFilter === 'SKILLS' ? 'about_btn-active' : ''}`} onClick={()=> setAboutFilter('SKILLS')}>Skills</button>
              <button className={`about_btn ${aboutFilter === 'AWARD' ? 'about_btn-active' : ''}`} onClick={()=> setAboutFilter('AWARD')}>Award</button>
            </div>
          </Col>

          <Col lg="8" md="9">
            
            {
              aboutFilter === 'ABOUT' && <div className="about_content_wrapper d-flex gap-5">

              <div className="about_img w-25">
                  <img src={aboutImg} alt="" className="w-100" />
              </div>

              <div className="about_content w-75">
                  <h2>I'm samiul alam</h2>
                  <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequuntur delectus repellat cumque? Debitis quam atque magnam tempora, autem repellendus vel. Nostrum soluta necessitatibus dolor rem beatae. Porro ex voluptatum quisquam?</p>
                  <div className="social_links">
                      <h6 className="mb-3">Connect with me:</h6>
                      <span><a href="https://www.facebook.com/" target="_blank"><i class="ri-facebook-line"></i></a></span>
                      <span><a href="https://www.youtube.com/" target="_blank"><i class="ri-youtube-line"></i></a></span>
                      <span><a href="https://www.github.com/" target="_blank"><i class="ri-github-line"></i></a></span>
                      <span><a href="https://www.instagram.com/" target="_blank"><i class="ri-instagram-line"></i></a></span>
                      <span><a href="https://www.linkedin.com/" target="_blank"><i class="ri-linkedin-line"></i></a></span>
                  </div>
              </div>

            </div>
            }

            {
              aboutFilter === 'EDUCATION' && <Education/>
            }

            {
              aboutFilter === 'SKILLS' && <Skills/>
            }

            {
              aboutFilter === 'AWARD' && <Award/>
            }

          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
