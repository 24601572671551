import React from "react";
import "./hero-section.css";

import { Container, Row, Col } from "reactstrap";

import Typewriter from "typewriter-effect";

import heroImg from "../../assets/hero.jpg";

const HeroSection = () => {
  return (
    <section className="hero__section" id="home">
      <Container>
        <Row>
          <Col lg="6">
            <div className="hero__content">
              <p className="mb-3">Welcom to my world!</p>
              {/* <h5 className='mb-4'>Hi</h5> */}
              <h2 className="hero__title mb-4">
                Hello I am
                <Typewriter
                  options={{
                    strings: ["Samiul alam", "a web developer", "a content creator", "a youtuber"],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Est
                eligendi suscipit culpa unde aut quidem quae modi expedita nihil
                sed. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Omnis, officiis.
              </p>

              <div className="mt-5 hero__btns d-flex align-items-center gap-4">
                <button className="btn hire__btn">
                  <a href="#">Hire Me</a>
                </button>
                <button className="btn">Contact</button>
              </div>
            </div>
          </Col>

          <Col lg="6">
            <div className="hero__img">
              <img src={heroImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
